import {axiosData} from "@/store/api/axios";

const state = () => ({
  espacios: JSON.parse(localStorage.getItem('espacios')) || null,
  espacio: JSON.parse(localStorage.getItem('espacio')) || null,

});

const getters = {
  getEspacios(state) {
    return state.espacios;
  },
  getEspacio(state) {
    return state.espacio;
  },
};

const actions = {
  setEspacio({ commit }, payload) {
    commit('SET_ESPACIO', payload);
    payload
        ? localStorage.setItem('espacio', JSON.stringify(payload))
        : localStorage.removeItem('espacio');
  },
  setEspacios({ commit }, payload) {
    commit('SET_ESPACIOS', payload);
    payload
        ? localStorage.setItem('espacios', JSON.stringify(payload))
        : localStorage.removeItem('espacios');
  },
  async editarEspacioNegocio(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'espacios/negocio/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async dameEspaciosxZonaEvento(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'espacios/evento/zonatag/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async dameEspaciosxZonaNegocio(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'espacios/negocio/zonatag/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async dameEspaciosxZonaEventoId(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'espacios/evento/zona/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
  async getEspaciosByZona(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    this.dispatch('ui/setTextLoading', 'Cargando Mesas & Boxes');

    try {
      const data = await axiosData(
          true,
          'espacios/negocio/zona/'+tag,
          'get',
          false,
          false
      );

      if(data && data!== 404 && data !==400){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }else{
        this.dispatch('ui/handleLoaderBtn', false);

      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }},
};

const mutations = {
  SET_ESPACIOS(state, payload) {
    state.espacios = payload;
    localStorage.setItem('espacios', JSON.stringify(payload));
  },
  SET_ESPACIO(state, payload) {
    state.espacio = payload;
    localStorage.setItem('espacio', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
